<template>
  <div class="barcode-reader">
    <StreamBarcodeReader
      @decode="(a) => onDecode(a)"
      @loaded="(x) => onLoaded(x)"
    ></StreamBarcodeReader>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";


export default {
  name: "barcode-reader",
  components: {
    StreamBarcodeReader,
  },
  data() {
    return {
      loaded: false
    };
  },
  props: {
    msg: String
  },
  methods: {
    onDecode(a) {
      if(a.startsWith("https")){
        let code = a.slice(a.length - 6)
        this.$emit('scan', code)
      }else{
        this.$emit('scan', a)
      }
    },
    checkLoad: function() {
      if (this.loaded == false) {
        alert(this.$t('titles.camera-error'))
      }
    },
    onLoaded() {
      this.loaded = true;
    },
  },
  mounted() {
    setTimeout(this.checkLoad,10000);
  }
};
</script>

<style lang="scss">
.barcode-reader {
  .overlay-element {
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
  }
  .laser {
    animation: scanning-data 2s infinite;
  }
}

@keyframes scanning-data {
  50% {
    transform: translateY(100px);
  }
}
</style>
