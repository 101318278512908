<template>
  <div
    class="error-tooltip__text"
    :class="{
      '--show': open,
      '--top': position == 'top',
      '--right': position == 'right',
      '--bottom': position == 'bottom',
      '--left': position == 'left',
    }"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "j-validation-tooltip",
  props: {
    text: String,
    open: Boolean,
    //top,right,bottom,left
    position: {
      type: String,
      default() {
        return "top";
      },
    },
  },
};
</script>

<style lang="scss">
.--show {
  visibility: visible !important;
}

.error-tooltip__text {
  visibility: hidden;
  background-color: #751616;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  font-size: 17px;
  position: absolute;
  box-shadow: 0 0 3px grey;
  height: fit-content;
  z-index: 1;
  &::after {
    content: " ";
    position: absolute;
    border-style: solid;
  }
  &.--top {
    left: 50%;
    margin-left: -94px;
    margin-bottom: 10px;
    &::after {
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-color: #751616 transparent transparent transparent;
    }
  }
  &.--right {
    top: -5px;
    left: 105%;
    margin-left: 10px;
    &::after {
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-color: transparent #751616 transparent transparent;
    }
  }
  &.--bottom {
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    margin-top: 10px;
    &::after {
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-color: transparent transparent #751616 transparent;
    }
  }
  &.--left {
    margin-left: -200px;
    left: -2%;
    bottom: 10%;
    &::after {
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-color: transparent transparent transparent #751616;
    }
  }
}
</style>