<template>
  <div id="layout_default">
    <div class="toolbar">
      <span class="material-icons md-36 menu-btn" @click.stop="sidebar"
        >menu</span
      >
      <div class="toolbar__btn">
        <locale-changer />
        <dark-mode />
      </div>
      <div class="toolbar__background"></div>
      <span class="toolbar__logo" />
    </div>

    <div
      id="sidebar"
      v-click-outside="closeSidebar"
      :class="{ transition: !wait, 'show-sidebar': showSidebar ,'transition-back': wait}"
    >
      <span class="material-icons sidebar__icon" @click="closeSidebar"> arrow_back_ios </span>
      <router-link to="/">{{ $t("pages.concept") + " 1" }}</router-link>
      <router-link to="/2">{{ $t("pages.concept") + " 2" }}</router-link>
      <router-link to="/3">{{ $t("pages.concept") + " 3" }}</router-link>

      <router-link to="/about">{{ $t("pages.about") }}</router-link>

      <router-link to="/error">{{ $t("pages.error") }}</router-link>

      <router-link to="/faq">{{ $t("pages.faq") }}</router-link>

      <router-link to="/help">{{ $t("pages.help") }}</router-link>

      <router-link to="/info">{{ $t("pages.info") }}</router-link>

      <router-link to="/settings">{{ $t("pages.settings") }}</router-link>
    </div>
    <router-view id="page-content" />
  </div>
</template>

<script>
import DarkMode from "../components/dark-mode.vue";
import LocaleChanger from "../components/locale-changer.vue";
export default {
  components: {
    LocaleChanger,
    DarkMode,
  },
  data() {
    return {
      showSidebar: false,
      wait: true,
    };
  },
  methods: {
    sidebar() {
      this.showSidebar = true;
    },
    closeSidebar: function() {
      this.wait = true;
      setTimeout(() => (this.showSidebar = false), 150);
    }
  },
  watch: {
    $route() {
      this.closeSidebar();
    },
    showSidebar: function (v) {
      if (v == true) {
        setTimeout(() => (this.wait = false), 100);
      }
    },

  },
};
</script>

<style lang="scss">
.sidebar__icon {
  padding-top: 27px !important;
  &:hover {
    cursor: pointer;
      color: #b42629;
  }
}
.transition {
  transform: translate(0, 0) !important;
}
.transition-back {
  transform: translate(0, 0);
}
.show-sidebar {
  display: block !important;
}
#page-content {
  padding-top: 80px;
  overflow: hidden;
}
.menu-btn {
  transition: transform 0.5s;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    text-shadow: 0 0 2px rgb(170, 170, 170);
  }
}
.toolbar {
  width: 100%;
  position: absolute;
  box-sizing: content-box;
  &__icon {
    padding-top: 30px;
  }
}

#sidebar {
  height: 100%;
  display: none;
  background-color: #f2f2f2;
  position: fixed;
  z-index: 6;
  overflow: auto;
  transform: translate(-240px, 0);
  transition: transform 0.5s;
  box-shadow: 1px 1px 5px 0 #c9c9c9;

  & > a {
    display: block;

    margin: 20px;
    padding: 20px 50px;
    color: #2c3e50;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    box-sizing: border-box;
    border-radius: 5px;
    &:hover {
      background-color: #e3e3e3;
      color: #b42629;
    }
  }
}
</style>
