<template>
  <!-- Site footer -->
  <footer class="j-footer">
    <hr />
    <div class="j-footer_half">
      <div class="footer-links col-6">
        <router-link to="/1">{{ $t("pages.home") }}</router-link>
        <router-link to="/about">{{ $t("pages.about") }}</router-link>
        <router-link to="/error">{{ $t("pages.error") }}</router-link>
        <router-link to="/faq">{{ $t("pages.faq") }}</router-link>
        <router-link to="/help">{{ $t("pages.help") }}</router-link>
        <router-link to="/info">{{ $t("pages.info") }}</router-link>
      </div>
    </div>
    <div class="j-footer_half">
      <p class="copyright-text">
        Copyright &copy; {{ new Date().getFullYear() }} All Rights Reserved by
        <a href="#">DMK IT Solutions GmbH</a>.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "j-footer",
};
</script>

<style lang="scss">
.j-footer {
  margin-top: 45px;
  padding: 0 0 20px;
  font-size: 15px;
  line-height: 24px;

  &_half {
    display: flex;

    justify-content: center;
  }

  hr {
    border-top-color: #bbb;
    opacity: 0.5;
  }

  a {
    color: #2c3e50;

    &:hover,
    &:active,
    &:focus {
      color: #dd211c;
    }
  }
}

.footer-links {
  display: flex;
  justify-content: space-around;

  padding: 16px;

  a {
    font-weight: bold;
  }

  a,
  a:active,
  a:focus {
    text-decoration: none;
  }
}
.copyright-text {
  margin-bottom: 0;
}
</style>
