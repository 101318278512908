var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"j-form"},[_c('div',{staticClass:"j-form__help",class:{
      '--isSticky': !_vm.showHelp && _vm.isSticky,
      '--notSticky': _vm.showHelp && !_vm.isSticky,
      '--active': _vm.showHelp && _vm.isSticky,
    },attrs:{"id":"help-tab"}},[_c('j-card',{staticClass:"help-card",attrs:{"lightRounded":""}},[(_vm.showHelp)?_c('div',{staticClass:"material-icons help-card__closeBtn",on:{"click":_vm.toggleHelp}},[_vm._v(" close ")]):_vm._e(),_vm._l((_vm.stepsArray),function(help,index){return _c('div',{key:'help' + index,staticClass:"help-card__content",class:{ '--visible': _vm.active == index }},[_c('div',{staticClass:"help-card__title'"},[_c('b',[_c('i18n',{attrs:{"path":"help.help-title","tag":"h2"},scopedSlots:_vm._u([{key:"index",fn:function(){return [_c('b',[_vm._v(_vm._s(index + 1))])]},proxy:true}],null,true)})],1)]),_c('div',{staticClass:"help-card__header"},[_vm._t('help' + index + '-header',[_c('p',[_vm._v(_vm._s(_vm.$t("forms.header")))])])],2),_c('div',{staticClass:"help-card__body"},[(_vm.active == index)?_vm._t('help' + index + '-content',[_c('p',[_vm._v(_vm._s(_vm.$t("forms.description")))])]):_vm._e()],2)])})],2)],1),_c('div',{staticClass:"j-form__sheet",class:{ '--help': _vm.showHelp }},[_c('h2',[_vm._v(_vm._s(_vm.$t("titles.request-sheet")))]),_c('br'),_c('div',{staticClass:"j-form__sheet__steps"},_vm._l((_vm.stepsArray),function(step,index){return _c('div',{key:'step' + index,staticClass:"j-form__sheet__step",class:{
          '--isActive': _vm.active == index,
          '--done': _vm.active > index,
          '--notActive': _vm.active < index,
          '--not-shown': index > _vm.active,
        },attrs:{"id":'step-id-' + index}},[_c('div',{staticClass:"j-form__sheet__content"},[_c('div',{staticClass:"j-form__sheet__header",class:{ '--disabled': index != _vm.active }},[_c('div',{staticClass:"j-form__sheet__nav",class:{ '--disabled': index != _vm.active },on:{"click":function($event){return _vm.toggleStep(index)}}},[_vm._v(" "+_vm._s(step)+" ")]),_c('div',{on:{"click":function($event){return _vm.toggleStep(index)}}},[_vm._t('step' + index + '-header',[_c('p',[_vm._v(_vm._s(_vm.$t("forms.header")))])])],2),_c('div',{staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.toggleStep(index)}}},[_c('j-tooltip',{attrs:{"disabled":index != _vm.active,"askIcon":"","text":_vm.$t('help.click-help'),"position":"right"}})],1)]),_c('div',{staticClass:"j-form__sheet__body"},[_vm._t('step' + index + '-content',[_vm._v(" "+_vm._s(_vm.$t("forms.content"))+" ")]),(_vm.stepsArray[_vm.stepsArray.length - 1] == index)?_c('j-btn',{attrs:{"lightRounded":"","color":"#70a9be","icon":"send","iconPosition":"right","title":_vm.$t('actions.send'),"disabled":index != _vm.active}}):_c('j-btn',{attrs:{"lightRounded":"","color":"#70a9be","title":_vm.$t('actions.continue'),"disabled":index != _vm.active},on:{"onClick":_vm.nextStep}})],2)])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }