<template>
  <j-btn color="#929292" icon="arrow_circle_up" @onClick="scrollTop" class="scroll-top-btn" :class="{'--visible': visible}" />
</template>

<script>
export default {
  name: 'scroll-top',
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 10);
    },
    scrollListener: function () {
      this.visible = window.scrollY > 150;
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style lang="scss">
.scroll-top-btn {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  
  cursor: pointer;

  transition: all 0.5s ease;

  &.--visible{
    visibility: visible;
    opacity: 1;
  }

  &.j-button{
    .button{
      padding: unset;

      border-radius: 50%;

      .icon{
        font-size: 50px;
      }
    }
  }
}
</style>

