<template>
  <div class="input-container">
    <div class="input-container__head">
      {{ title }}
    </div>
    <input
      :type="inputType"
      class="input-container__input"
      v-model="input"
      :placeholder="placeholder"
      :class="{ '--disabled': disabled }"
    />
  </div>
</template>

<script>
export default {
  name: "j-input",
  props: {
    inputType: {
      type: String,
      default() {
        return "text";
      }
    },
    disabled: Boolean,
    // if a value is already set
    val: [String, Number],
    title: String,
    placeholder: {
      type: String,
      default() {
        return "...";
      },
    },
  },
  mounted() {
    if (this.val !== undefined) {
      this.input = this.val;
    }
  },
  watch: {
    input: function (v) {
      this.$emit("value", v);
    },
    val: function () {
      this.input = this.val;
    }
  },
  data() {
    return {
      input: "",
    };
  },
};
</script>

<style lang="scss">
.input-container {
  &__head {
    margin: 10px 4px;
    font-size: 20px;
    text-align: left !important;
  }
  &__input {
    font-size: 20px;
    padding: 15px;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(199, 199, 199);
    color: #3c3c3c;
    &.--disabled {
      pointer-events: none;
      background-color: #f7f7f7;
    }
  }
}

*:focus {
  outline: none;
}
</style>