<template>
  <div class="checkbox-container">
    <div
      class="checkbox-container__item"
      v-for="(item, index) in texte"
      :key="item + index"
    >
      <div
        class="checkbox-container__item__required"
        v-if="required[index] == 'r'"
      >
        !
      </div>
      <div
        class="checkbox-container__item__box"
        :class="{ '--disabled': disabled }"
        @click="checkbox(index)"
      >
        <div
          v-if="checked[index] == index"
          class="checkbox-container__item__box__check material-icons"
        >
          check
        </div>
      </div>
      <div class="checkbox-container__item__text">
        <slot :name="'checkbox' + index">
          {{ item }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  // slots name = checkbox + index bsp = checkbox0
  // event: @accepted="indexe => ()" array[2] = -1 is unchecked ||  2 = checked
  name: "j-checkbox",
  props: {
    // if you use slots and you want to have more than 1 checkbox you need to give an array
    // with the length of how much you want and the item should be = ""
    texte: {
      type: Array,
      default() {
        return [""];
      },
    },
    // the user can choose only one
    onlyOneCheck: Boolean,
    // array with the lenght of 'texte' and when on the position of an element = 'r' then required
    required: {
      type: Array,
      default() {
        var r = [];
        for (var i = 0; i < this.texte.length; i++) {
          r.push("");
        }
        return r;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      checked: [],
    };
  },
  mounted() {
    for (var i = 0; i < this.texte.length; i++) {
      Vue.set(this.checked, i, -1);
    }
  },
  watch: {
    checked: function (v) {
      this.$emit("accepted", v);
    },
  },
  methods: {
    checkbox: function (i) {
      var test = this.checked[i];
      if (this.onlyOneCheck) {
        this.checked.forEach((e, index) => {
          if (index == i) {
            Vue.set(this.checked, index, i);
          } else {
            Vue.set(this.checked, index, -1);
          }
        });
      } else {
        if (test == -1) {
          Vue.set(this.checked, i, i);
        } else {
          Vue.set(this.checked, i, -1);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.checkbox-container {
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    &__required {
      color: rgb(121, 24, 24);
      font-size: 20px;
      margin-right: 10px;
      margin-top: 3px;
    }
    &__box {
      height: 25px;
      min-width: 26.22px;
      border: 1px solid gray;
      margin-right: 15px;
      border-radius: 5px;
      margin-top: 3px;
      &__check {
        color: green;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &__text {
      font-size: 20px;
      text-align: left;
    }
  }

  .--disabled {
    pointer-events: none;
    background-color: #f7f7f7;
  }

  .--alone {
    margin-top: 15px;
  }
}
</style>