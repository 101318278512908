<template>
  <div class="j-form">
    <div
      id="help-tab"
      class="j-form__help"
      :class="{
        '--isSticky': !showHelp && isSticky,
        '--notSticky': showHelp && !isSticky,
        '--active': showHelp && isSticky,
      }"
    >
      <j-card lightRounded class="help-card">
        <div
          v-if="showHelp"
          class="material-icons help-card__closeBtn"
          @click="toggleHelp"
        >
          close
        </div>
        <div
          v-for="(help, index) in stepsArray"
          :key="'help' + index"
          class="help-card__content"
          :class="{ '--visible': active == index }"
        >
          <div class="help-card__title'">
            <b>
              <i18n path="help.help-title" tag="h2">
                <template #index>
                  <b>{{ index + 1 }}</b>
                </template>
              </i18n>
            </b>
          </div>
          <div class="help-card__header">
            <slot :name="'help' + index + '-header'">
              <p>{{ $t("forms.header") }}</p>
            </slot>
          </div>
          <div class="help-card__body">
            <slot v-if="active == index" :name="'help' + index + '-content'">
              <p>{{ $t("forms.description") }}</p>
            </slot>
          </div>
        </div>
      </j-card>
    </div>
    <div class="j-form__sheet" :class="{ '--help': showHelp }">
      <h2>{{ $t("titles.request-sheet") }}</h2>
      <br />
      <div class="j-form__sheet__steps">
        <div
          :id="'step-id-' + index"
          v-for="(step, index) in stepsArray"
          :key="'step' + index"
          class="j-form__sheet__step"
          :class="{
            '--isActive': active == index,
            '--done': active > index,
            '--notActive': active < index,
            '--not-shown': index > active,
          }"
        >
          <div class="j-form__sheet__content">
            <div
              class="j-form__sheet__header"
              :class="{ '--disabled': index != active }"
            >
              <div
                class="j-form__sheet__nav"
                :class="{ '--disabled': index != active }"
                @click="toggleStep(index)"
              >
                {{ step }}
              </div>
              <div @click="toggleStep(index)">
                <slot :name="'step' + index + '-header'">
                  <p>{{ $t("forms.header") }}</p>
                </slot>
              </div>
              <div style="display: flex" @click="toggleStep(index)">
                <j-tooltip
                  :disabled="index != active"
                  askIcon
                  :text="$t('help.click-help')"
                  position="right"
                />
              </div>
            </div>

            <div class="j-form__sheet__body">
              <slot :name="'step' + index + '-content'">
                {{ $t("forms.content") }}
              </slot>
              <j-btn
                v-if="stepsArray[stepsArray.length - 1] == index"
                lightRounded
                color="#70a9be"
                icon="send"
                iconPosition="right"
                :title="$t('actions.send')"
                :disabled="index != active"
              />
              <j-btn
                v-else
                lightRounded
                color="#70a9be"
                :title="$t('actions.continue')"
                :disabled="index != active"
                @onClick="nextStep"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "j-form__sheet",
  props: {
    steps: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      active: 0,
      stepsArray: [],
      showHelp: false,
      isEdited: false,
      startDisctance: 0,
      isSticky: false,
      stickyClass: "is_sticky",
      validationError: {
        check: false,
        barcode: false,
        personalCode: false,
        safetyCode: false,
        terms1: false,
        terms2: false,
      },
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    toggleHelp() {
      this.showHelp = !this.showHelp;
    },
    toggleStep() {
      if (!this.showHelp) {
        this.toggleHelp();
      }
    },
    nextStep() {
      this.isEdited = true;
      this.active++;
      let id = "step-id-" + this.active;
      if (this.stepsArray.length != this.active) {
        document.getElementById(id).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    scrollListener() {
      var scrollTop = window.scrollY;
      var elementOffset = document.getElementById("help-tab").offsetTop;
      var distance = elementOffset - scrollTop;
      if (distance <= 80 && scrollTop >= this.startDisctance - 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },
  mounted() {
    if (this.steps > 0) {
      for (var i = 0; i < this.steps; i++) {
        this.stepsArray.push(i + 1);
      }
    }
    this.startDisctance = document.getElementById("help-tab").offsetTop;
    window.addEventListener("scroll", this.scrollListener);

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    if (this.windowWidth > 1300) {
      this.showHelp = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollListener);
  },
  watch: {
    isEdited() {
      this.$emit("edited", this.isEdited);
    },
    active() {
      this.$emit("active", this.active);
    },
  },
};
</script>

<style lang="scss">
.btn-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
}
.--not-shown {
  visibility: hidden;
}
.j-form {
  display: flex;
  justify-content: center;

  &__sheet {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0;
    transition: margin-left 1s ease, width 1s ease;

    h2 {
      color: $sa-red;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    &__step {
      display: flex;

      min-height: 300px;

      margin-bottom: 80px;

      &.--isActive {
        .j-form__sheet__nav {
          background: $sa-red;
        }
      }
      &.--notActive {
        .j-form__sheet__nav {
          background: #eaeaea;
        }

        color: #eaeaea;
      }
      &.--done {
        .j-form__sheet__nav {
          background: teal;
        }
        color: #96969b;
      }
    }

    &__nav {
      background: $sa-red; //default

      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 32px;
      max-width: 32px;

      margin-right: 16px;

      border-radius: 32px;

      color: white;
      font-weight: bold;

      cursor: pointer;

      &.--disabled {
        pointer-events: none;
      }
    }

    &__content {
      width: 100%;
    }

    &__header {
      display: flex;
      justify-items: center;
      align-items: center;

      text-align: left;

      div {
        cursor: pointer;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      height: 100%;
      min-height: 120px;

      padding: 16px;

      .j-button {
        margin-top: 20px;
      }
    }

    &.--help {
      width: 50%;

      margin-left: 50%;
    }
  }

  &__help {
    position: absolute;
    width: 50%;
    visibility: hidden;
    height: 100%;
    -webkit-transform: translateX(-150%);
    transform: translateX(-150%);
    z-index: 5;
    transition: all 1s ease;

    &.--isSticky {
      position: fixed;
      top: 80px;
      visibility: visible;
    }

    &.--notSticky {
      transform: translateX(-50%);
      visibility: visible;
    }
    &.--active {
      position: fixed;
      transform: translateX(-50%);
      visibility: visible;
      top: 80px;
    }

    .help-card {
      display: flex;
      background-color: #f9f9f9;
      box-shadow: 0 0 6px #b3b3b3;
      height: 88%;
      justify-content: center;
      background-image: url("../../assets/images/svg/faq-background.svg");
      background-repeat: no-repeat;
      background-attachment: fixed;
      &__closeBtn {
        position: absolute;
        top: 0;
        right: 0;

        margin: 24px;

        font-size: 30px !important;
        transition: transform 1s;

        &:hover {
          cursor: pointer;
          transform: scale(1.15);
        }
      }

      &__content {
        display: none;

        flex-direction: column;

        width: 100%;
      }

      &__title {
        margin-right: auto;
      }
    }
  }

  &__help,
  &__sheet {
    padding: 16px;
  }

  .--disabled {
    pointer-events: none;
  }

  .--visible {
    display: flex;
  }
}
</style>