<template>
  <div>
    
  </div>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style>

</style>
