<template>
  <div
    class="j-wizard"
    :style="wColors"
    :class="{ '--direction-column': navTopAlign }"
  >
    <div
      class="j-wizard__nav"
      :class="{ '--nav-direction-row': navTopAlign, '--w-100': navTopAlign }"
    >
      <div
        class="j-wizard__nav__item"
        v-for="(step, index) in steps"
        :key="index"
        :class="{ '--direction-top-nav-item': navTopAlign }"
        :style="{ height: 100 / steps.length + '%' }"
      >
        <div
          class="j-wizard__nav__item__text"
          :class="{
            '--justify-center': navTopAlign,
            '--w-unset': navTopAlign,
            '--first-element': index == 0,
            '--last-element': index == steps.length - 1,
            '--last-element-top': index == steps.length - 1 && navTopAlign,
          }"
        >
          {{ step }}
        </div>
        <div
          class="j-wizard__nav__item__line"
          :class="{ '--nav-direction-line': navTopAlign }"
        >
          <div
            class="j-wizard__nav__item__bar"
            :class="{ '--active-bar': active >= index, '--w-100': navTopAlign }"
          ></div>
          <div
            class="j-wizard__nav__item__icon material-icons"
            :class="{ '--active-icon': active == index }"
          >
            {{ icons[index] }}
          </div>
          <div
            class="j-wizard__nav__item__bar"
            :class="{ '--active-bar': active > index, '--w-100': navTopAlign }"
          ></div>
        </div>
      </div>
    </div>
    <div class="j-wizard__content" :class="{ '--w-unset': navTopAlign }">
      <slot name="finished" v-if="active == steps.length"></slot>
      <div
        v-for="(content, index) in steps"
        :key="content + index"
        :style="{ display: active == index ? 'flex' : 'none' }"
      >
        <slot :name="'step' + index"> </slot>
      </div>
      <div class="j-wizard__content__btns">
        <j-btn
          :title="$t('actions.back')"
          :color="color"
          hoverLighter
          rounded
          @onClick="active--"
          v-if="active > 0"
        />
        <div v-else></div>
        <j-btn
          :title="$t('actions.finish')"
          :color="shadeColor(color, -20)"
          hoverLighter
          rounded
          @onClick="finish"
          v-if="active == steps.length - 1"
        />
        <j-btn
          :title="$t('actions.continue')"
          :color="shadeColor(color, -20)"
          hoverLighter
          rounded
          @onClick="checkBefore"
          v-else-if="active < steps.length - 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
// slot name = step + index bsp: #step0
export default {
  name: "j-wizard",
  props: {
    // titles
    stepNames: Array,
    color: String,
    icons: Array,
    navTopAlign: Boolean,
    // need to return Bool
    checkBeforeNextStep: {
      type: Function,
      default() {
        return this.noCheck();
      },
    },
  },
  mounted() {
    if (this.icons.length > 0 && this.stepNames === undefined) {
      for (var i = 0; i < this.icons.length; i++) {
        this.steps.push("");
      }
    } else if (this.stepNames.length > 0) {
      this.steps = this.stepNames;
    }
  },
  computed: {
    wColors() {
      return {
        "--color": this.color,
        "--light-color": this.shadeColor(this.color, 60),
        "--darker-color": this.shadeColor(this.color, -20),
      };
    },
  },
  data() {
    return {
      active: 0,
      steps: [],
    };
  },
  methods: {
    checkBefore: function () {
      var test = this.checkBeforeNextStep(this.active);
      if (test == true) {
        this.active++;
      }
    },
    noCheck: function () {
      return true;
    },
    finish: function() {
      this.checkBefore();
      this.$emit('finished');
    },
    shadeColor: function (color, percent) {
      if (color === undefined) {
        return;
      }
      var R = parseInt(color.substring(1, 3), 16);
      var G = parseInt(color.substring(3, 5), 16);
      var B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      var RR =
        R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    },
  },
};
</script>

<style lang="scss" scoped>
.--first-element {
  border-top-left-radius: 15px;
}
.--last-element {
  border-bottom-left-radius: 15px;
}
.--last-element-top {
  border-top-right-radius: 15px;
  border-bottom-left-radius: 0 !important;
}
.--direction-column {
  flex-direction: column !important;
}
.--direction-row {
  flex-direction: row !important;
}
.--direction-top-nav-item {
  flex-direction: column !important;
  width: 100%;
  justify-content: unset !important;
}
.--nav-direction-row {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly !important;
}
.--nav-direction-line {
  flex-direction: row !important;
  justify-content: center;
  background: linear-gradient(180deg, var(--color) 50%, #ffffff 50%) !important;
}
.--w-100 {
  width: 100% !important;
}
.--justify-center {
  justify-content: center !important;
}
.--w-unset {
  width: unset !important;
}
.--active-icon {
  text-shadow: 0 0 4px white;
}
.--active-bar {
  background-color: var(--light-color) !important;
}
.j-wizard {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--color);
  box-shadow: 0 0 5px rgb(212, 212, 212);
  border-radius: 15px;
  &__content {
    padding: 45px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__btns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__nav {
    justify-content: space-between;
    border-top-left-radius: 15px;
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__text {
        color: white;
        padding: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: var(--color);
        text-shadow: 0 0 7px var(--darker-color);
        text-align: left;
      }
      &__icon {
        background-color: var(--light-color);
        border-radius: 50%;
        color: white;
        padding: 20px 20px;
        box-shadow: 0 0 7px var(--darker-color);
      }
      &__line {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(90deg, var(--color) 50%, #ffffff 50%);
      }
      &__bar {
        min-width: 10px;
        background-color: var(--darker-color);
        height: 100%;
        min-height: 10px;
      }
    }
  }
}
</style>