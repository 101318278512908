<template>
  <div class="j-concept-2" style="margin-top: 30px !important">
    <j-wizard-v-2
      :stepTitles="wizardTitles"
      :checkBeforeNextStep="validationCheck"
    >
      <!-- <template #step0>
            <div class="wiz__title">
              <div></div>
              {{ $t("titles.request-sheet") }}
              <div class="material-icons wiz__help" @click="helpModal1 = true">
                help_outline
              </div>
            </div>
          </template> -->
      <template #step0>
        <div class="wizard__step">
          <div class="wizard__title2">
            <div style="min-width: 22px"></div>
            {{ $t("titles.request-sheet") }}
            <div class="material-icons wizard__help-icon" @click="helpModal1 = true">
              help_outline
            </div>
          </div>
          <div class="validation-container">
            <j-validation-tool-tip
              :text="$t('help.required-accept')"
              :position="windowWidth > 860 ? 'left' : 'top'"
              :open="openMessageError.firstCheck"
              :style="{ bottom: windowWidth > 860 ? 'unset' : '106%' }"
            />
            <j-check
              :texte="checktext"
              @accepted="(x) => (checkedFirstStep = x[0])"
            />
          </div>
          <div style="font-size: 20px; margin: 30px 10px; text-align: left">
            {{ $t("actions.and") }}
          </div>
          <div>
            <div class="wizard__barcode-container">
              <div class="validation-container">
                <j-validation-tool-tip
                  :text="$t('help.required')"
                  :position="windowWidth > 860 ? 'left' : 'top'"
                  :open="openMessageError.barcode"
                  :style="{ bottom: windowWidth > 860 ? '5%' : '94%'}"
                />
                <j-input
                  :title="$t('titles.scan-barcode')"
                  class="wizard__barcode-container__input"
                  @value="(x) => (barcode = x)"
                  :val="barcode"
                />
              </div> 
              <j-btn
                :title="$t('actions.scan')"
                color="#ab3234"
                hoverLighter
                class="wizard__scan-btn"
                lightRounded
                iconPosition="left"
                icon="camera_alt"
                @onClick="startBarcodeScan"
              />
            </div>
            <div class="wizard__information">
              <div class="material-icons" style="margin-right: 5px">
                error_outline
              </div>
              {{ $t("help.check-again") }}
            </div>
            <img src="@/assets/images/barcode.png" />
          </div>
          <div style="min-height: 22px"></div>
        </div>
      </template>
      <!--  -->
      <template #step1>
        <div class="wizard__step">
          <div class="wizard__title2" style="margin-bottom: 20px !important">
            <div style="min-width: 22px"></div>
            {{ $t("titles.personal-qrcode") }}
            <div class="material-icons wizard__help-icon" @click="helpModal2 = true">
              help_outline
            </div>
          </div>
          <div class="wizard__text">
            {{ $t("request-sheet.step.4") }}
          </div>
          <div class="validation-container">
            <j-validation-tool-tip
              :text="$t('help.required-scan')"
              :position="windowWidth > 860 ? 'left' : 'top'"
              :open="openMessageError.personalCode"
              style="bottom: 32%"
            />
           <j-btn
              :title="$t('actions.scan')"
              color="#ab3234"
              hoverLighter
              lightRounded
              iconPosition="left"
              icon="camera_alt"
              style="margin-bottom: 20px; width: 200px"
              @onClick="scannedPersonalCode"
            />
          </div>
          <div class="wizard__row">
            <j-input
              :title="$t('forms.firstname')"
              class="wizard__input"
              :val="testPersonalCode[1]"
              @value="x => testPersonalCode[1] = x"
            />
            <j-input
              :title="$t('forms.lastname')"
              class="wizard__input"
              :val="testPersonalCode[0]"
              @value="x => testPersonalCode[0] = x"
            />
          </div>
          <div class="wizard__row">
            <j-input
              :title="$t('forms.street')"
              class="wizard__input"
              :val="testPersonalCode[2]"
              @value="x => testPersonalCode[2] = x"
            />
            <j-input
              :title="$t('forms.housenumber')"
              class="wizard__input"
              :val="testPersonalCode[3]"
              @value="x => testPersonalCode[3] = x"
            />
          </div>
          <div class="wizard__row">
            <j-input
              :title="$t('forms.post-code')"
              class="wizard__input"
              :val="testPersonalCode[4]"
              @value="x => testPersonalCode[4] = x"
            />
            <j-input
              :title="$t('forms.place')"
              class="wizard__input"
              :val="testPersonalCode[5]"
              @value="x => testPersonalCode[5] = x"
            />
          </div>
          <div class="wizard__row">
            <j-input
              :title="$t('forms.phonenumber')"
              class="wizard__input"
              :val="testPersonalCode[6]"
              @value="x => testPersonalCode[6] = x"
            />
            <j-input
              :title="$t('forms.email')"
              class="wizard__input"
              :val="testPersonalCode[7]"
              inputType="email"
              @value="x => testPersonalCode[7] = x"
            />
          </div>
            <j-input
              :title="$t('forms.date-of-birth')"
              class="wizard__single-input"
              :val="testPersonalCode[8]"
              style="margin-bottom: 20px"
              @value="x => testPersonalCode[8] = x"
            />
        </div>
      </template>
      <!--  -->
      <template #step2>
        <div class="wizard__step">
          <div class="wizard__title2">
            <div style="min-width: 22px"></div>
            {{ $t("titles.participation-code") }}
            <div class="material-icons wizard__help-icon" @click="helpModal3 = true">
              help_outline
            </div>
          </div>
          <div class="wizard__step" style="min-height: unset !important">
            <div class="wizard__text">
              {{ $t("request-sheet.step.3") }}
            </div>
            <div class="validation-container">
              <j-validation-tool-tip
                :text="$t('help.required-scan')"
                :position="windowWidth > 860 ? 'left' : 'top'"
                :open="openMessageError.safetyCode"
                :style="{ bottom: windowWidth > 860 ? '8%' : '188%' }"
              />
              <j-btn
                :title="$t('actions.scan')"
                color="#ab3234"
                hoverLighter
                lightRounded
                icon="camera_alt"
                style="width: 300px"
                iconPosition="left"
                @onClick="scan = true"
              />
            </div>
          </div>

         <j-safety
            :codeLength="6"
            :val="participationCode"
            @code="(v) => (participationCode = v)"
            style="margin: 60px 0"
          />
          <j-check
            :texte="agreeText"
            :required="['r', 'r']"
            @accepted="(x) => (agreeChecked = x)"
            style="margin: 10px 50px 50px 50px"
          >
            <template #checkbox0>
              <div class="validation-container">
                <j-validation-tool-tip
                  :text="$t('help.required-accept')"
                  :position="windowWidth > 860 ? 'left' : 'top'"
                  :open="openMessageError.agreeText1"
                  :style="{
                    bottom: windowWidth > 860 ? '11%' : '83%',
                    left: windowWidth > 860 ? '-39%' : '-11%',
                  }"
                />
                <i18n path="request-sheet.step.agree" tag="p">
                  <template #terms>
                    <router-link :to="{ path: '/faq', hash: '#tos' }">{{
                      $t("request-sheet.tos")
                    }}</router-link>
                  </template>
                </i18n>
              </div>
              <!-- {{$t("request-sheet.step.consent")}}  -->
            </template>
            <template #checkbox1>
              <div class="validation-container">
                <j-validation-tool-tip
                  :text="$t('help.required-accept')"
                  :position="windowWidth > 860 ? 'left' : 'top'"
                  :open="openMessageError.agreeText2"
                  :style="{
                    bottom: windowWidth > 860 ? '11%' : '83%',
                    left: windowWidth > 860 ? '-39%' : '-11%',
                  }"
                />
                <i18n path="request-sheet.step.agree" tag="p">
                  <template #terms>
                    <router-link :to="{ path: '/faq', hash: '#gdpr' }">{{
                      $t("request-sheet.gdpr")
                    }}</router-link>
                  </template>
                </i18n>
              </div>
              <!-- {{$t("request-sheet.step.privacy-policy")}}  -->
            </template>
          </j-check>
          <!-- <div class="min-heigth-200"></div> -->
        </div>
      </template>
      <template #step3>
        <div class="wizard__step">
          <j-done-animation />
        </div>
      </template>
    </j-wizard-v-2>
    <!--  -->
    <j-scan-modal v-if="scan" @close="scan = false" @scan="x => getScanValue(x)"/>
    <!--  -->
    <j-modal
      v-if="helpModal1"
      :title="$t('help.step-title') + '1'"
      closeIcon
      @close="helpModal1 = false"
    >
      <template>
        <div>{{ $t("help.example") }}:</div>
        <div style="width: 100%; height: 200px; border: 1px solid grey"></div>
      </template>
    </j-modal>
    <!--  -->
    <j-modal
      v-if="helpModal2"
      :title="$t('help.step-title') + '2'"
      closeIcon
      @close="helpModal2 = false"
    >
      <template>
        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</div>
      </template>
    </j-modal>
    <!--  -->
    <j-modal
      v-if="helpModal3"
      :title="$t('help.step-title') + '3'"
      closeIcon
      @close="helpModal3 = false"
    >
      <template>
        <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
      </template>
    </j-modal>
  </div>
</template>

<script>
import JWizardV2 from "../j-components/j-wizard-v2.vue";
import JValidationToolTip from "../j-components/j-validation-error";
import JDoneAnimation from "../j-components/j-done-animation.vue";

export default {
  name: "j-concept-2",
  components: {
    JWizardV2,
    JValidationToolTip,
    JDoneAnimation,
  },
  data() {
    return {
      wizardTitles: [
        this.$t("titles.request-sheet"),
        this.$t("titles.personal-qrcode"),
        this.$t("titles.participation-code"),
        this.$t("actions.finish"),
      ],
      agreeText: [
        this.$t("request-sheet.step.consent"),
        this.$t("request-sheet.step.privacy-policy"),
      ],
      agreeChecked: [],
      checktext: [this.$t("checkboxes.first-step")],
      checkedFirstStep: -1,
      barcode: "",
      helpModal1: false,
      helpModal2: false,
      helpModal3: false,
      scan: false,
      participationCode: "",
      testPersonalCode: [
      ],
      openMessageError: {
        firstCheck: false,
        barcode: false,
        personalCode: false,
        safetyCode: false,
        agreeText1: false,
        agreeText2: false,
      },
      windowWidth: window.innerWidth,
      barcodeReader: false,
      personalCodeReader: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    scannedPersonalCode: function() {
      this.scan = true;
      this.personalCodeReader = true;
    },
    getScanValue: function(val) {
      if (this.barcodeReader == true) {
        this.barcode = val;
        this.barcodeReader = false;
      } else if (this.personalCodeReader == true) {
        var splitted = val.split(';');
        for (var i = 0; i < splitted.length; i++) {
          if (i == 2) {
            var splittedStreet = splitted[2].split(' ');
            splitted[2] = splittedStreet[0];
            splitted.splice(3,0,splittedStreet[1]);
          }
        }
        this.testPersonalCode = splitted;
        this.personalCodeReader = false;
      } else {
        this.participationCode = val;
      }
    },
    startBarcodeScan: function() {
      this.scan = true;
      this.barcodeReader = true;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    validationCheck: function (active) {
      var test = true;
      if (active == 0) {
        if (this.checkedFirstStep == -1) {
          this.openMessageError.firstCheck = true;
          test = false;
        } else {
          this.openMessageError.firstCheck = false;
        }
        if (this.barcode.length < 6) {
          this.openMessageError.barcode = true;
          test = false;
        } else {
          this.openMessageError.barcode = false;
        }
      } else if (active == 1) {
        if (this.testPersonalCode.length < 9) {
          this.openMessageError.personalCode = true;
          test = false;
        } else {
          this.openMessageError.personalCode = false;
        }
      } else if (active == 2) {
        if (this.participationCode.length < 6) {
          this.openMessageError.safetyCode = true;
          test = false;
        } else {
          this.openMessageError.safetyCode = false;
        }
        if (this.agreeChecked[0] == -1) {
          this.openMessageError.agreeText1 = true;
          test = false;
        } else {
          this.openMessageError.agreeText1 = false;
        }

        if (this.agreeChecked[1] == -1) {
          this.openMessageError.agreeText2 = true;
          test = false;
        } else {
          this.openMessageError.agreeText2 = false;
        }
      }
      return test;
    },
  },
};
</script>

<style lang="scss">
.min-heigth-200 {
  min-height: 200px;
}
.j-concept-2 {
  margin: 20px;
}
.wizard {
  &__help-icon {
    color: gray;
      font-size: 30px !important;
      transition: transform 1s;
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
  }
  &__title2 {
    font-size: 28px;
    color: #c21514;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    width: 100%;
    font-weight: 500;
    text-shadow: 0 0 2px #d2d2d2;
  }
}
</style>