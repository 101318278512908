<template>
  <div class="error-page">

      <div class="error-page__title">{{$t("titles.not-found-error")}}</div>

  </div>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style lang="scss">
.error-page {
  background-image: url("../assets/images/svg/error-background.svg");
  height: 100%;
  margin: 40px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  &__title {
    font-size: 35px;
    padding: 50px;
  }
}
</style>