<template>
  <div
    class="card"
    :class="{ '--lightRounded': lightRounded, '--rounded': rounded }"
  >
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "j-card",
  props: {
    lightRounded: Boolean,
    rounded: Boolean,
  },
};
</script>

<style>
.--lightRounded {
  border-radius: 10px;
}
.--rounded {
  border-radius: 20px;
}
.card {
  background-color: white;
  box-shadow: 0 0 13px rgb(218, 218, 218);
  padding: 40px;
}
</style>