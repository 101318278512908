<template>
  <div
    class="tabs"
    :class="{
      '--containerBorder': containerBorder,
      '--navbarBackground': navbarBackground,
    }"
  >
    <div
      class="tabs__navbar"
      :class="{ '--tabsOverFullWidth': tabsOverFullWidth }"
    >
      <div
        class="tabs__navbar__item"
        v-for="(item, index) in tabsNames"
        :key="item + index"
        :class="{
          '--active': active == index,
          '--tabsOverFullWidthItem': tabsOverFullWidth,
        }"
        :style="{ marginLeft: index == 0 ? '20px' : 0 }"
        @click="active = index"
      >
        {{ item }}
      </div>
    </div>
    <div class="tabs__content">
      <div v-for="(item, index) in tabsNames" :key="index + item">
        <slot :name="'tab' + index" v-if="active == index"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
// slot name= tab + index bsp= #tab0
export default {
  name: "j-tabs",
  props: {
    tabsNames: Array,
    containerBorder: Boolean,
    // gray background in header
    navbarBackground: Boolean,
    tabsOverFullWidth: Boolean,
  },
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 500px) {
  .--tabsOverFullWidthItem {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 800px) {
  .--tabsOverFullWidthItem {
    width: unset !important;
  }
}
.--containerBorder {
  border: 1px solid rgb(179, 179, 179);
}
.--navbarBackground {
  background-color: rgb(245, 245, 245) !important;
}
.--tabsOverFullWidth {
  justify-content: space-around;
}
.--tabsOverFullWidthItem {
  text-align: center;
  width: 20%;
}
.tabs {
  background-color: white;
  &__navbar {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(179, 179, 179);
    padding-top: 20px;
    &__item {
      font-size: 20px;
      padding: 15px 25px;
      color: rgb(65, 65, 65);
      &:hover {
        cursor: pointer;
        background-color: #e7e7e7;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
      &.--active {
        background-color: white !important;
        border: 1px solid rgb(179, 179, 179);
        border-bottom: none;
        margin-bottom: -2px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
    }
  }
  &__content {
    background-color: white;
    min-height: 200px;
  }
}
</style>