<template>
  <div class="scan-modal">
    <div class="scan-modal__item">
      <div class="scan-modal__item__header">
        <div
          class="material-icons scan-modal__item__header__closeBtn"
          @click="$emit('close')"
        >
          close
        </div>
      </div>
      <barcode-reader
        @scan="(x) => scanned(x)"
        style="height: 100%; position: fixed; width: 100%; top:0; bottom: 0;"
      />
    </div>
  </div>
</template>

<script>
import BarcodeReader from "../barcode-reader.vue";

export default {
  //event: @close
  //event: @scan="value => DoSomething"
  name: "j-scan-modal",
  components: {
    BarcodeReader,
  },
  methods: {
    scanned: function(item) {
      this.$emit('scan', item);
      this.$emit("close");
    }
  },
};
</script>

<style lang="scss">
video {
  width: 100%;
  height: 100%;
}
.scanner-container {
  height: 100%;
  display: flex;
  div {
    width: 100%;
  }
}
.scan-modal {
  display: flex;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: #05191791;
  height: 100%;
  position: fixed;
  width: 100%;
  justify-content: center;
  align-items: center;
  &__item {
    height: 100%;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 15px #484848;
    border-radius: 15px;
    position: relative;
    &__header {
      padding: 25px 40px;
      position: fixed;
      width: 100%;
      z-index: 5;
      text-align: right;
      &__closeBtn {
        font-size: 40px !important;
        transition: transform 1s;
        color: white;
        &:hover {
          cursor: pointer;
          transform: scale(1.15);
        }
      }
    }
  }
}
</style>