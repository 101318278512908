<template>
  <div>
    <div class="question" v-for="(group, index) in groups" :key="group">
      <div class="question__group" @click="groupToggle(index)">
        {{ group }}
        <div class="question__icon material-icons" v-if="open[index]">
          keyboard_arrow_down
        </div>
        <div class="question__icon material-icons" v-else>
          keyboard_arrow_up
        </div>
      </div>
      <div
        class="question__questions"
        :style="{ maxHeight: open[index] ? '2000px' : '0px' }"
        v-if="open[index]"
      >
        <div v-for="(item, index) in items" :key="'item' + index">
          <j-question-card
            v-if="item.group == group"
            :question="item.question"
            :answer="item.answer"
            :routeId="item.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JQuestionCard from "./j-question-card.vue";
import Vue from "vue";

export default {
  name: "j-questions",
  components: {
    JQuestionCard,
  },
  props: {
    groups: Array,
    // array item must be = { group: "...", question: "...", answer="..." , id: 0 (only need for link)}
    items: Array,
  },
  data() {
    return {
      open: [],
    };
  },
  mounted() {
    for (var i = 0; i < this.groups.length; i++) {
      console.log(this.$route);

      if (this.$route.hash != "") {
        console.log("hey");
        var found = false;
        this.items.forEach((e) => {
          if (
            e.id == "tos" &&
            e.group == this.groups[i] &&
            this.$route.hash == "#tos"
          ) {
            console.log("found");
            Vue.set(this.open, i, true);
            found = true;
          } else if (
            e.id == "gdpr" &&
            e.group == this.groups[i] &&
            this.$route.hash == "#gdpr"
          ) {
            console.log("found");
            Vue.set(this.open, i, true);
            found = true;
          }
        });
        if (found == true) {
          break;
        }
      }
      Vue.set(this.open, i, false);
    }
  },
  methods: {
    groupToggle: function (i) {
      var actual = this.open[i];
      Vue.set(this.open, i, !actual);
    },
  },
};
</script>

<style lang="scss">
.question {
  &__questions {
    transition: max-height 0.3s;
  }
  &__group {
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    width: 100%;
    padding: 20px 40px;
    color: white;
    background-color: #71181a;
    border-radius: 7px;
    margin: 10px 0;
    box-shadow: 0 0 5px rgb(180, 180, 180);
    &:hover {
      cursor: pointer;
    }
  }
  &__icon {
    font-size: 30px !important;
  }
}
</style>