<template>
  <div class="dark-mode">
    <j-btn v-if="!darkmode" notColorFilled noBorder color="#838990" class="dark-mode__btn" icon="wb_sunny" @onClick="changeMode"/>
    <j-btn v-else notColorFilled noBorder color="#838990" class="dark-mode__btn" icon="nights_stay" @onClick="changeMode"/>
  </div>
</template>

<script>
export default {
  data(){
    return {
      darkmode: false,
    }
  },
  methods:{
    changeMode() {
      this.darkmode = !this.darkmode
      var root = document.documentElement;
      if(this.darkmode){
        root.classList.add('--dark');
      }else{
        root.classList.remove('--dark');
      }
    }
  }
}
</script>

<style lang="scss">
.dark-mode{
  &__btn{
    display: flex;
    align-items: center;

    height: 40px;
    width: 40px;
  }
  .icon{
    font-size: 30px !important;
  }
}
</style>