import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import i18n from "./i18n/i18n.js";

//Global
//Style
import "./assets/scss/style.scss";
//Components
import "@/webComponents.js";
//Icons
import "material-design-icons-iconfont/dist/material-design-icons.css";
//Events
import "@/clickOutsideEvent.js";

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
