<template>
  <div class="q-card">
    <div class="q-card__question" @click="open = !open">
      <div class="q-card__question__text">
        {{ question }}
      </div>
      <div class="q-card__question__icon material-icons" v-if="!open">
        keyboard_arrow_down
      </div>
      <div class="q-card__question__icon material-icons" v-else>
        keyboard_arrow_up
      </div>
    </div>
    <div
      class="q-card__answer"
      :id="routeId"
      :style="{
        maxHeight: open ? '1000px' : '0px',
      }"
    >
      <slot v-if="open">
        <div class="q-card__answer__text">
          {{ answer }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "j-queston-card",
  props: {
    question: {
      type: String,
      require: true,
    },
    answer: {
      type: String,
      require: true,
    },
    routeId: String,
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    if (this.$route.hash == "#" + this.routeId) {
      this.open = true;
    }
  }
};
</script>

<style lang="scss">
.q-card {
  margin: 10px;
  box-shadow: 0 0 5px rgb(185, 185, 185);
  border-radius: 7px;
  &__question {
    display: flex;
    justify-content: space-between;
    font-size: 23px;
    padding: 20px 28px;
    border-radius: 7px;
    background-color: white;
    &__text {
      color: black;
      font-weight: 500;
      text-align: left;
    }
    &__icon {
      font-size: 33px !important;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__answer {
    border-top: none;
    transition: max-height 0.5s;
    background-color: white;
    border-radius: 7px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    &__text {
          padding: 20px 28px;

      text-align: left;
      font-size: 20px;
    }
  }
}
</style>