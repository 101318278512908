<template>
  <div class="modal">
    <div class="modal__item">
      <div
        class="modal__item__header"
        v-if="noHeader != true"
        :class="{ '--closeBtnPositions': closeIcon }"
      >
        <div v-if="closeIcon" style="min-width: 41px"></div>
        <div class="modal__item__header__title" :style="{ color: titleColor }">
          {{ title }}
        </div>
        <div
          v-if="closeIcon"
          class="material-icons modal__item__header__closeBtn"
          @click="$emit('close')"
        >
          close
        </div>
      </div>
      <div class="modal__item__content">
        <div class="modal__item__content__slot">
          <slot> </slot>
        </div>
        <div v-if="standartBtns" class="standartBtns">
          <j-button
            :title="$t('buttons.cancel')"
            rounded
            color="#970e0e"
            hoverLighter
            @onClick="$emit('close')"
          />
          <j-button
            :title="$t('buttons.okay')"
            rounded
            color="#039403"
            hoverLighter
            @onClick="$emit('accepted')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JButton from "./j-button.vue";

export default {
  // if standartBtns
  //event: @close=
  //event @accepted
  name: "j-modal",
  props: {
    title: String,
    noHeader: Boolean,
    titleColor: String,
    // cancel & okay
    standartBtns: Boolean,
    closeIcon: Boolean,
  },
  components: {
    JButton,
  },
};
</script>

<style lang="scss">
.standartBtns {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.--closeBtnPositions {
  justify-content: space-between;
}
.modal {
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: #05191791;
  height: 100%;
  position: fixed;
  width: 100%;
  justify-content: center;
  align-items: center;
  &__item {
    height: max-content;
    width: 800px;
    background-color: white;
    box-shadow: 0px 0px 15px #484848;
    border-radius: 15px;
    &__header {
      display: flex;
      flex-direction: row;
      padding: 25px 40px;
      &__closeBtn {
        font-size: 30px !important;
        transition: transform 1s;
        color: #b23232;
        &:hover {
          cursor: pointer;
          transform: scale(1.15);
        }
      }
      &__title {
        width: 100%;
        font-size: 30px !important;
      }
    }
    &__content {
      padding: 0 40px 40px 40px;
      &__slot {
        padding: 0px 20px;
      }
    }
  }
}
</style>