<template>
  <div class="j-button" :style="colors">
    <div
      class="button"
      @click.stop="$emit('onClick')"
      :class="{
        '--lightrounded': lightRounded,
        '--rounded': rounded,
        '--hoverDarker': hoverDarker,
        '--hoverLighter': hoverLighter,
        '--notFullFilled': notColorFilled,
        '--disabled': disabled,
        '--borderless': noBorder,
      }"
    >
      <div
        v-if="iconPosition == 'left'"
        class="material-icons icon --left"
        :class="{ '--noMargin': !title }"
      >
        {{ icon }}
      </div>
      {{ title }}
      <div
        v-if="iconPosition == 'right'"
        class="material-icons icon --right"
        :class="{ '--noMargin': !title }"
      >
        {{ icon }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // event: @onClick="method"
  name: "j-button",
  props: {
    title: String,
    lightRounded: Boolean,
    rounded: Boolean,
    icon: String,
    // Postions: left & right
    iconPosition: {
      typ: String,
      default() {
        return "left";
      },
    },
    // no background color but border
    notColorFilled: Boolean,
    //reult only seen if notCollorFilled = true
    noBorder: Boolean,
    disabled: Boolean,
    // only #HEX because of hover
    color: String,
    //hovers only use when Full Filled
    hoverDarker: Boolean,
    hoverLighter: Boolean,
  },
  computed: {
    colors() {
      return {
        "--light-hover": this.shadeColor(this.color, 15),
        "--darker-hover": this.shadeColor(this.color, -15),
        "--color": this.color,
      };
    },
  },
  methods: {
    shadeColor: function (color, percent) {
      if (color === undefined) {
        return;
      }
      var R = parseInt(color.substring(1, 3), 16);
      var G = parseInt(color.substring(3, 5), 16);
      var B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      var RR =
        R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    },
  },
};
</script>

<style lang="scss">
.j-button {
  .--noMargin {
    margin: 0 !important;
  }
  .--disabled {
    pointer-events: none;
    background-color: rgb(146, 146, 146) !important;
    color: rgb(194, 194, 194) !important;
  }
  .--notFullFilled {
    background-color: transparent !important;
    border: 1px solid var(--color);
    color: var(--color) !important;
    &:hover {
      background-color: transparent !important;
      box-shadow: 0 0 3px var(--color);
      text-shadow: 0 0 3px var(--color);
    }
  }
  .--borderless {
    border: none;
    box-shadow: none !important;
  }
  .icon {
    font-size: 20px;
    display: flex;
    align-items: center;
    &.--left {
      margin-right: 5px;
    }
    &.--right {
      margin-left: 5px;
    }
  }
  .--hoverDarker:hover {
    background-color: var(--darker-hover) !important;
  }
  .--hoverLighter:hover {
    background-color: var(--light-hover) !important;
  }
  .--rounded {
    border-radius: 15px;
  }
  .--lightrounded {
    border-radius: 5px;
  }
  .button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    font-size: 19px;
    align-content: center;
    color: white;
    background-color: var(--color);
    box-shadow: 0 0 3px rgb(201, 201, 201);
    &:hover {
      cursor: pointer;
    }
  }
}
</style>