import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//layouts
import Layout from "../layouts/default.vue";
import Standalone from "../layouts/standalone.vue";

//start page
// import Home from "../pages/PageHome.vue";

//concepts
import JConcept1 from "../components/j-concepts/j-concept.vue";
import JConcept2 from "../components/j-concepts/j-concept-2.vue";
import JConcept3 from "../components/j-concepts/j-concept-3.vue";
//web pages
import About from "../pages/PageAbout.vue";
import FAQ from "../pages/PageFAQ.vue";
import Help from "../pages/PageHelp.vue";
import Info from "../pages/PageInfo.vue";
import Settings from "../pages/PageSettings.vue";

//error
import ErrorPage from "../pages/PageError.vue";


const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      { path: "/", name: "Home", component: JConcept1 },
      { path: "/1", name: "Concept1", component: JConcept1 },
      { path: "/2", name: "Concept2", component: JConcept2 },
      { path: "/3", name: "Concept3", component: JConcept3 },
      { path: "/about", name: "About", component: About },
      { path: "/faq", name: "FAQ", component: FAQ },
      { path: "/help", name: "Help", component: Help },
      { path: "/info", name: "Info", component: Info },
      { path: "/settings", name: "Settings", component: Settings },
    ],
  },
  {
    path: "/",
    component: Standalone,
    children: [{ path: "*", name: "Error", component: ErrorPage }],
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;

    }

    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
