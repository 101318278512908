<template>
  <div class="tooltip" :class="{'--disabled': disabled}">
    <div :class="{ 'material-icons': askIcon}">
      {{ askIcon ? "help_outline" : hoverText }}
    </div>
    <span
      v-if="!disabled"
      class="tooltiptext"
      :class="{
        '--top': position == 'top',
        '--right': position == 'right',
        '--bottom': position == 'bottom',
        '--left': position == 'left',
      }"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "j-tooltip",
  props: {
    text: String,
    hoverText: String,
    askIcon: Boolean,
    //top,right,bottom,left
    position: {
      type: String,
      default() {
        return "top";
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
};
</script>

<style lang="scss">
.tooltip {
  position: relative;
  display: flex;

  margin: 0px 8px;

  color: #96969b;
  cursor: pointer;

  &.--disabled{
    pointer-events: none;
    color: #ededed;
    .tooltiptext {
    visibility: hidden;
    }
  }

  .tooltiptext {
    visibility: hidden;
    min-width: 120px;
    background-color: #d4d7d9;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  &::after {
    content: " ";
    position: absolute;
    border-style: solid;
  }
  &.--top {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    margin-bottom: 10px;
    &::after {
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-color: #d4d7d9 transparent transparent transparent;
    }
  }
  &.--right {
    top: -5px;
    left: 105%;
    margin-left: 10px;
    &::after {
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-color: transparent #d4d7d9 transparent transparent;
    }
  }
  &.--bottom {
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    margin-top: 10px;
    &::after {
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-color: transparent transparent #d4d7d9 transparent;
    }
  }
  &.--left {
    top: -5px;
    right: 105%;
    margin-right: 10px;
    &::after {
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-color: transparent transparent transparent #d4d7d9;
    }
  }
}
</style>