<template>
  <div>
    <j-wizard
      color="#71181a"
      :icons="icons"
      navTopAlign
      :checkBeforeNextStep="validationCheck"
      style="margin: 30px 20px;"
 >
      <!--  -->
      <template #step0>
        <div class="wizard__step">
          <div class="wizard__title">
            <div>
              <div v-if="windowWidth < 450" class="material-icons wizard__help" @click="helpModal1 = true">
                help_outline
              </div>
            </div>
            {{ $t("titles.request-sheet") }}
            <div class="material-icons wizard__help">
              <span v-if="windowWidth > 450" @click="helpModal1 = true">
                help_outline
              </span> 
            </div>
          </div>
          <div class="validation-container">
            <j-validation-tool-tip
              :text="$t('help.required')"
              :style="{ bottom: windowWidth > 900 ? '10px' : '109%' }"
              :position="windowWidth > 900 ? 'left' : 'top'"
              :open="openMessageError.firstCheck"
            />
            <j-check
              :texte="checktext"
              @accepted="(x) => (checkedFirstStep = x[0])"
            />
          </div>
          <div style="font-size: 20px; margin: 30px 10px; text-align: left">
            {{ $t("actions.and") }}
          </div>
          <div>
            <div class="wizard__barcode-container">
              <div class="validation-container">
                <j-validation-tool-tip
                  :style="{ bottom: windowWidth > 900 ? '7%' : '85%' }"
                  :text="$t('help.required')"
                  :position="windowWidth > 900 ? 'left' : 'top'"
                  :open="openMessageError.barcode"
                />
                <j-input
                  class="wizard__barcode-container__input"
                  :title="$t('titles.scan-barcode')"
                  @value="(x) => (barcode = x)"
                  :val="barcode"
                />
              </div>
              <j-btn
                :title="$t('actions.scan')"
                color="#ab3234"
                hoverLighter
                class="wizard__scan-btn"
                lightRounded
                iconPosition="left"
                icon="camera_alt"
                @onClick="startBarcodeScan"
              />
            </div>
            <div class="wizard__information">
              <div class="material-icons" style="margin-right: 5px">
                error_outline
              </div>
              {{ $t("help.check-again") }}
            </div>
            <img src="@/assets/images/barcode.png" />
          </div>
          <div style="min-height: 22px"></div>
        </div>
      </template>
      <!--  -->
      <template #step1>
        <div class="wizard__step">
          <div class="wizard__title" style="margin-bottom: 20px !important">
            <div>
               <div v-if="windowWidth < 450" class="material-icons wizard__help" @click="helpModal2 = true">
                help_outline
              </div>
            </div>
            {{ $t("titles.personal-qrcode") }}
            <div class="material-icons wizard__help">
               <span v-if="windowWidth > 450" @click="helpModal2 = true">
                help_outline
              </span> 
            </div>
          </div>
          <div class="wizard__text">
            {{ $t("request-sheet.step.4") }}
          </div>
          <div class="validation-container">
            <j-validation-tool-tip
              :text="$t('help.required-scan')"
              :position="windowWidth > 900 ? 'left' : 'top'"
              :open="openMessageError.personalCode"
              style="bottom: -32%"
            />
            <j-btn
              :title="$t('actions.scan')"
              color="#ab3234"
              hoverLighter
              lightRounded
              iconPosition="left"
              icon="camera_alt"
              style="margin-bottom: 20px; width: 200px"
              @onClick="scannedPersonalCode"
            />
          </div>
          <div class="wizard__row">
            <j-input
              :title="$t('forms.firstname')"
              class="wizard__input"
              :val="testPersonalCode[1]"
              @value="x => testPersonalCode[1] = x"
            />
            <j-input
              :title="$t('forms.lastname')"
              class="wizard__input"
              :val="testPersonalCode[0]"
              @value="x => testPersonalCode[0] = x"
            />
          </div>
          <div class="wizard__row">
            <j-input
              :title="$t('forms.street')"
              class="wizard__input"
              :val="testPersonalCode[2]"
              @value="x => testPersonalCode[2] = x"
            />
            <j-input
              :title="$t('forms.housenumber')"
              class="wizard__input"
              :val="testPersonalCode[3]"
              @value="x => testPersonalCode[3] = x"
            />
          </div>
          <div class="wizard__row">
            <j-input
              :title="$t('forms.post-code')"
              class="wizard__input"
              :val="testPersonalCode[4]"
              @value="x => testPersonalCode[4] = x"
            />
            <j-input
              :title="$t('forms.place')"
              class="wizard__input"
              :val="testPersonalCode[5]"
              @value="x => testPersonalCode[5] = x"
            />
          </div>
           <div class="wizard__row">
            <j-input
              :title="$t('forms.phonenumber')"
              class="wizard__input"
              :val="testPersonalCode[6]"
              @value="x => testPersonalCode[6] = x"
            />
            <j-input
              :title="$t('forms.email')"
              class="wizard__input"
              :val="testPersonalCode[7]"
              inputType="email"
              @value="x => testPersonalCode[7] = x"
            />
            </div>
            <j-input
              :title="$t('forms.date-of-birth')"
              class="wizard__single-input"
              :val="testPersonalCode[8]"
              style="margin-bottom: 20px"
              @value="x => testPersonalCode[8] = x"
            />
        </div>
      </template>
      <!--  -->
      <template #step2>
        <div class="wizard__step">
          <div class="wizard__title">
            <div>
              <div
                v-if="windowWidth < 450"
                class="material-icons wizard__help"
                @click="helpModal3 = true">
                help_outline
              </div>
            </div>
            {{ $t("titles.participation-code") }}
            <div class="material-icons wizard__help">
              <span v-if="windowWidth > 450" @click="helpModal3 = true">
                help_outline
              </span>
            </div>
          </div>

          <div class="wizard__text">
            {{ $t("request-sheet.step.3") }}
          </div>
          <div class="validation-container">
            <j-validation-tool-tip
              :text="$t('help.required-scan')"
              :position="windowWidth > 860 ? 'left' : 'top'"
              :open="openMessageError.safetyCode"
              :style="{ bottom: windowWidth > 860 ? '8%' : '223%' }"
            />
            <j-btn
              :title="$t('actions.scan')"
              color="#ab3234"
              hoverLighter
              lightRounded
              icon="camera_alt"
              style="width: 300px"
              iconPosition="left"
              @onClick="scan = true"
            />
          </div>
          <j-safety
            :codeLength="6"
            :val="participationCode"
            @code="(v) => (participationCode = v)"
            style="margin: 60px 0"
          />
          <j-check
            :texte="agreeText"
            :required="['r', 'r']"
            @accepted="(x) => (agreeChecked = x)"
            style="margin: 10px 50px 50px 50px"
          >
            <template #checkbox0>
              <div class="validation-container">
                <j-validation-tool-tip
                  :text="$t('help.required-accept')"
                  :position="windowWidth > 860 ? 'left' : 'top'"
                  :open="openMessageError.agreeText1"
                  :style="{
                    bottom: windowWidth > 860 ? '11%' : '83%',
                    left: windowWidth > 860 ? '-39%' : '-11%',
                  }"
                />
                <i18n path="request-sheet.step.agree" tag="p">
                  <template #terms>
                    <router-link
                      :to="{ path: '/faq', hash: '#tos' }"
                      target="_blank"
                      >{{ $t("request-sheet.tos") }}</router-link
                    >
                  </template>
                </i18n>
              </div>
            </template>
            <template #checkbox1>
              <div class="validation-container">
                <j-validation-tool-tip
                  :text="$t('help.required-accept')"
                  :position="windowWidth > 860 ? 'left' : 'top'"
                  :open="openMessageError.agreeText2"
                  :style="{
                    bottom: windowWidth > 860 ? '11%' : '71%',
                    left: windowWidth > 860 ? '-39%' : '-11%',
                  }"
                />
                <i18n path="request-sheet.step.agree" tag="p">
                  <template #terms>
                    <router-link
                      :to="{ path: '/faq', hash: '#gdpr' }"
                      target="_blank"
                      >{{ $t("request-sheet.gdpr") }}</router-link
                    >
                  </template>
                </i18n>
              </div>
            </template>
          </j-check>
          <div style="min-height: 30px"></div>
        </div>
      </template>
      <template #finished>
        <div class="wizard__step">
          <JDoneAnimation />
        </div>
      </template>
    </j-wizard>
    <!--  -->
    <j-scan-modal v-if="scan" @close="scan = false" @scan="x => getScanValue(x)" />
    <!--  -->
    <j-modal
      v-if="helpModal1"
      :title="$t('help.step-title') + '1'"
      closeIcon
      @close="helpModal1 = false"
    >
      <template>
        <div>{{ $t("help.example") }}:</div>
        <div style="width: 100%; height: 200px; border: 1px solid grey"></div>
      </template>
    </j-modal>
    <!--  -->
    <j-modal
      v-if="helpModal2"
      :title="$t('help.step-title') + '2'"
      closeIcon
      @close="helpModal2 = false"
    >
      <template>
        <div class="help-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</div>
      </template>
    </j-modal>
    <!--  -->
    <j-modal
      v-if="helpModal3"
      :title="$t('help.step-title') + '3'"
      closeIcon
      @close="helpModal3 = false"
    >
      <template>
        <div class="help-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At</div>
      </template>
    </j-modal>
  </div>
</template>

<script>
import JValidationToolTip from "../j-components/j-validation-error.vue";
import JDoneAnimation from "../j-components/j-done-animation.vue";

export default {
  name: "j-concept",
  components: {
    JValidationToolTip,
    JDoneAnimation,
  },
  data() {
    return {
      icons: ["filter_frames", "qr_code_2", "done"],
      wizardTitles: [
        this.$t("titles.request-sheet"),
        this.$t("titles.personal-qrcode"),
        this.$t("titles.participation-code"),
      ],
      agreeText: [
        this.$t("request-sheet.step.consent"),
        this.$t("request-sheet.step.privacy-policy"),
      ],
      agreeChecked: [],
      checktext: [this.$t("checkboxes.first-step")],
      checkedFirstStep: -1,
      barcode: "",
      helpModal1: false,
      helpModal2: false,
      helpModal3: false,
      scan: false,
      participationCode: "",
      testPersonalCode: [
      ],
      openMessageError: {
        firstCheck: false,
        barcode: false,
        personalCode: false,
        safetyCode: false,
        agreeText1: false,
        agreeText2: false,
      },
      windowWidth: window.innerWidth,
      barcodeReader: false,
      personalCodeReader: false
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    scannedPersonalCode: function() {
      this.scan = true;
      this.personalCodeReader = true;
    },
    getScanValue: function(val) {
      if (this.barcodeReader == true) {
        this.barcode = val;
        this.barcodeReader = false;
      } else if (this.personalCodeReader == true) {
        var splitted = val.split(';');
        for (var i = 0; i < splitted.length; i++) {
          if (i == 2) {
            var splittedStreet = splitted[2].split(' ');
            splitted[2] = splittedStreet[0];
            splitted.splice(3,0,splittedStreet[1]);
          }
        }
        this.testPersonalCode = splitted;
        this.personalCodeReader = false;
      } else {
        this.participationCode = val;
      }
    },
    startBarcodeScan: function() {
      this.scan = true;
      this.barcodeReader = true;
    },
    validationCheck: function (active) {
      var test = true;
      if (active == 0) {
        if (this.checkedFirstStep == -1) {
          this.openMessageError.firstCheck = true;
          test = false;
        } else {
          this.openMessageError.firstCheck = false;
        }
        if (this.barcode.length < 6) {
          this.openMessageError.barcode = true;
          test = false;
        } else {
          this.openMessageError.barcode = false;
        }
      } else if (active == 1) {
        if (this.testPersonalCode.length < 9) {
          this.openMessageError.personalCode = true;
          test = false;
        } else {
          this.openMessageError.personalCode = false;
        }
      } else if (active == 2) {
        if (this.participationCode.length < 6) {
          this.openMessageError.safetyCode = true;
          test = false;
        } else {
          this.openMessageError.safetyCode = false;
        }
        if (this.agreeChecked[0] == -1) {
          this.openMessageError.agreeText1 = true;
          test = false;
        } else {
          this.openMessageError.agreeText1 = false;
        }

        if (this.agreeChecked[1] == -1) {
          this.openMessageError.agreeText2 = true;
          test = false;
        } else {
          this.openMessageError.agreeText2 = false;
        }
      }
      return test;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 650px) {
  .dropdown-container__input,
  .dark-mode,
  .toolbar__btn {
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .wiz {
    padding: 20px !important;
  }
  .j-concept-2 {
    margin: 0 !important;
  }
}
@media only screen and (max-width: 800px) {
  .wizard__barcode-container {
    flex-direction: column;
  }
  .wizard__scan-btn {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
  .wizard__information {
    width: unset !important;
  }
  .faq-card {
    margin: 10px !important;
  }
  .card {
    padding: 30px 10px !important;
  }
  .j-form__sheet__header {
    width: 82%;
  }
  .j-form__sheet__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 450px) {
  .wizard {
    &__title {
      flex-direction: column;
    }
    &__help {
      display: flex !important;
      justify-content: flex-end;
      margin-bottom: 10px;
      &:hover {
        transform: scale(1) !important;
      }
    }

  }
}
@media only screen and (max-width: 500px) {
  .wizard__barcode-container__input {
    width: 350px !important;
  }
  .code-input__item {
    margin-right: 2px !important;
  }
  .wizard__step .wizard__single-input {
    width: 350px !important;
  }
  .wizard__input {
    width: 350px !important;
  }
  .j-wizard__content {
    padding: 20px !important;
  }
  .checkbox-container {
    padding: 0 20px;
  }
  .checkbox-container__item__text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .wizard__row {
    flex-direction: column !important;
  }
  .wizard__input:nth-child(even) {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 1250px) {
  .j-form__help .help-card {
    background-position-x: -200px;
  }
  .j-form__help {
    width: 100% !important;
  }
  .j-form__help.--notSticky {
    transform: translateX(0%) !important;
  }
  .j-form__help.--active {
    transform: translateX(0%) !important;
  }
  .j-form__sheet.--help {
    margin-left: 0 !important;
  }
}
@media only screen and (min-width: 1880px) {
  .wiz__card {
    margin: 50px 250px;
  }
}
.validation-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}
.wizard {
  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    min-height: 750px;
    &__content {
      width: 100%;
      margin-left: 70px;
      text-align: left;
    }
  }
  &__title {
    font-size: 28px;
    color: #c21514;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    width: 100%;
    font-weight: 500;
    text-shadow: 0 0 2px #d2d2d2;
  }
  &__information {
    width: 540px;
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  &__input {
    width: 400px;
    &:nth-child(even) {
      margin-left: 20px;
    }
  }
  &__single-input {
    width: 820px;
  }
  &__text {
    margin-bottom: 10px;
    font-size: 23px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__help {
    color: gray;
    font-size: 30px !important;
    transition: transform 1s;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
  &__scan-btn {
    margin-top: 47px;
    margin-left: 10px;
  }
  &__barcode-container {
    display: flex;
    justify-content: flex-start;
    &__input {
      width: 420px;
    }
  }
}
.help-text {
  text-align: left;
}
</style>