<template>
  <div>
    <j-form
      @active="(x) => (active = x)"
      @edited="(status) => $emit('edited', status)"
      :steps="5"
    >
      <template #step0-header>
        <i18n path="titles.stick-barcode" tag="p">
          <template #barcode>
            <b>{{ $t("request-sheet.barcode") }}</b>
          </template>
          <template #test-tube>
            <b>{{ $t("request-sheet.test-tube") }}</b>
          </template>
        </i18n>
      </template>
      <template #step0-content>
        <div class="request-sheet__check">
          <j-check :disabled="active != 0">
            <template #checkbox0>
              <i18n path="request-sheet.step.1-slot" tag="p">
                <template #barcode>
                  <span>{{ $t("request-sheet.barcode") }}</span>
                </template>
                <template #test-tube>
                  <span>{{ $t("request-sheet.test-tube") }}</span>
                </template>
              </i18n>
            </template>
          </j-check>
        </div>
      </template>

      <template #step1-header>
        <i18n path="request-sheet.step.2-slot" tag="p">
          <template #barcode>
            <b>{{ $t("request-sheet.barcode") }}</b>
          </template>
          <template #test-tube>
            <b>{{ $t("request-sheet.test-tube") }}</b>
          </template>
        </i18n>
      </template>
      <template #step1-content>
        <div class="request-sheet__btn">
          <div style="margin-right: 20px">
            <j-btn
              lightRounded
              hoverDarker
              color="#d4d7d9"
              icon="qr_code_scanner"
              @onClick="scan = true"
              :disabled="active != 1"
              class="qr-code-btn"
            />
            <div class="request-sheet__btn__title">
              {{ $t("request-sheet.barcode") }}
            </div>
          </div>
          <div class="">
            <i18n path="titles.done-right" tag="p">
              <template #code>
                <span>{{ $t("request-sheet.barcode") }}</span>
              </template>
            </i18n>
            <j-input :disabled="active != 1" />
            <div class="request-sheet__warning" style="margin-top: 10px">
              <i class="material-icons">error_outline</i>
              <span>{{ $t("help.check-again") }}</span>
            </div>
            <img src="@/assets/images/barcode.png" alt="" />
          </div>
        </div>
      </template>

      <template #step2-header>
        <i18n path="request-sheet.step.3-slot" tag="p">
          <template #qr-code>
            <b>{{ $t("request-sheet.qr-code") }}</b>
          </template>
        </i18n>
      </template>
      <template #step2-content>
        <div class="request-sheet__btn">
          <div style="margin-right: 20px;" class="centered">
            <j-btn
              lightRounded
              hoverDarker
              color="#d4d7d9"
              icon="qr_code_scanner"
              @onClick="scan = true"
              :disabled="active != 2"
              class="qr-code-btn"
            />
            <div class="request-sheet__btn__title">
              {{ $t("request-sheet.qr-code") }}
            </div>
          </div>
          <div class="request-sheet__safety">
            <i18n path="titles.done-right" tag="p">
              <template #code>
                <b>{{ $t("request-sheet.participation-code") }}</b>
              </template>
            </i18n>
            <j-safety :disabled="active != 2" :codeLength="6"></j-safety>
          </div>
        </div>
      </template>

      <template #step3-header>
        <i18n path="request-sheet.step.4-slot" tag="p">
          <template #personalized-qr-code>
            <b>{{ $t("request-sheet.personalized-qr-code") }}</b>
          </template>
        </i18n>
      </template>
      <template #step3-content>
        <div class="request-sheet__btn">
          <div class="centered">
            <j-btn
              lightRounded
              hoverDarker
              color="#d4d7d9"
              icon="qr_code_scanner"
              @onClick="scan = true"
              :disabled="active != 3"
              class="qr-code-btn"
            />
            <div class="request-sheet__btn__title">
              {{ $t("request-sheet.qr-code") }}
            </div>
          </div>
          <div class="centered">
            <j-btn
              lightRounded
              hoverDarker
              disabled
              color="#d4d7d9"
              @onClick="scan = true"
              icon="badge"
              class="qr-code-btn "
            />
            <div class="request-sheet__btn__title">
              {{ $t("request-sheet.id-card") }}
            </div>
          </div>
        </div>
        <div class="request-sheet__row">
          <j-input :title="$t('forms.firstname')"  />
          <j-input :title="$t('forms.lastname')"  />
        </div>
        <j-input
          class="request-sheet__input"
          :title="$t('forms.date-of-birth')"
        />
        <div class="request-sheet__row">
          <j-input :title="$t('forms.street')" />
          <j-input :title="$t('forms.housenumber')" />
        </div>
        <div class="request-sheet__row">
          <j-input :title="$t('forms.post-code')"/>
          <j-input :title="$t('forms.place')"  />
        </div>
        <div class="request-sheet__row">
          <j-input :title="$t('forms.email')"  />
          <j-input :title="$t('forms.phonenumber')" />
        </div>
      </template>

      <template #step4-header>
        <i18n path="request-sheet.step.5-slot" tag="p">
          <template #consent>
            <b>{{ $t("request-sheet.consent") }}</b>
          </template>
          <template #send>
            <b>{{ $t("actions.send") }}</b>
          </template>
        </i18n>
      </template>
      <template #step4-content>
        <j-check :texte="terms" :disabled="active != 4">
          <template #checkbox0>
            <i18n path="request-sheet.step.agree" tag="p">
              <template #terms>
                <router-link :to="{ path: '/faq', hash: '#tos' }">{{
                  $t("request-sheet.tos")
                }}</router-link>
              </template>
            </i18n>
          </template>

          <template #checkbox1>
            <i18n path="request-sheet.step.agree" tag="p">
              <template #terms>
                <router-link :to="{ path: '/faq', hash: '#gdpr' }">{{
                  $t("request-sheet.gdpr")
                }}</router-link>
              </template>
            </i18n>
          </template>
        </j-check>
      </template>
    </j-form>
    <j-scan-modal v-if="scan" @close="scan = false" />
  </div>
</template>

<script>
import JForm from "@/components/j-components/j-form.vue";
export default {
  name: "j-conept-3",
  components: {
    JForm,
  },
  data() {
    return {
      active: 0,
      steps: [],
      terms: ["", ""],
      scan: false,
    };
  },
};
</script>

<style scoped>
p {
  width: 80%;
}
</style>

<style lang="scss">
@media only screen and (max-width: 600px) {
  .request-sheet {
    &__btn {
      flex-direction: column;
    }
    &__row {
      flex-direction: column !important;
    }
  }
  .request-sheet__row .input-container:nth-child(even) {
    margin: 0 !important;
  }
  .input-container, .request-sheet__input ,.wizard__step .wizard__single-input {
    width: 300px !important;
  }
  .qr-code-btn {
    width: 400px;
  }
  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.request-sheet {
  &__btn {
    display: flex;
    justify-content: space-evenly;

    width: 100%;

    &__title {
      margin-top: 4px;
      text-transform: capitalize;

      font-weight: bold;
    }

    .icon {
      font-size: 50px !important;
      color: #2c3e50;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .input-container {
      &:nth-child(even) {
        margin-left: 20px;
      }
    }
  }

  &__input {
    width: 497px;
  }

  &__check {
    display: flex;
    justify-content: center;

    width: 100%;
  }

  &__safety {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  &__warning {
    display: flex;
    align-items: center;
    align-self: flex-start;
  }
}
</style>
