<template>
  <div class="dropdown-container" ref="dropdownContainer">
    <div class="dropdown-container__title" v-if="title">
      {{ title }}
    </div>
    <div
      class="dropdown-container__input"
      @click="containerOpen = !containerOpen"
    >
      <div
        class="dropdown-container__input__value"
        :class="{ '--unset': choosedItem == 'Auswählen...' }"
      >
        {{ choosedItem }}
      </div>
      <div
        v-if="containerOpen"
        class="dropdown-container__input__icon material-icons"
      >
        keyboard_arrow_up
      </div>
      <div v-else class="dropdown-container__input__icon material-icons">
        keyboard_arrow_down
      </div>
    </div>
    <div
      class="dropdown-container__items"
      :class="{ '--show': containerOpen }"
      :style="{ width: containerWidth + 'px' }"
    >
      <div
        class="dropdown-container__items__item"
        v-for="(item, index) in items"
        :key="item + ' ' + index"
        @click="clickedItem(item, index)"
        :class="{ '--active': choosedItem == item }"
      >
        <div
          v-if="icons"
          class="dropdown-container__items__icon material-icons"
        >
          {{ icons[index] }}
        </div>
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
// event = @choosed="(item,index) =>  DoSomething(item)"
export default {
  name: "dropdown",
  props: {
    title: String,
    items: Array,
    // icon muss an der position im array stehen wozu er bei items gehört
    icons: Array,
    placeHolder: {
      type: String,
      default() {
        return "Auswählen...";
      },
    },
  },
  data() {
    return {
      containerOpen: false,
      choosedItem: "",
      containerWidth: 0,
    };
  },
  mounted() {
    this.choosedItem = this.placeHolder;
    this.containerWidth = this.$refs.dropdownContainer.clientWidth;
  },
  methods: {
    clickedItem: function (item, index) {
      this.choosedItem = item;
      this.containerOpen = false;
      this.$emit("choosed", item, index);
    },
  },
};
</script>

<style lang="scss">
.dropdown-container {
  font-size: 17px;
  &__title {
    margin: 10px 4px;
  }
  &__input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(199, 199, 199);
    &:hover {
      cursor: pointer;
    }
  }
  &__items {
    display: none;
    flex-direction: column;
    position: absolute;
    margin-top: 2px;
    z-index: 2;
    max-height: 400px;
    background-color: white;
    overflow: scroll;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 5px;
    overflow-y: scroll;
    box-shadow: 0 0 5px #dddddd;
    padding: 20px;
    &__item {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.--active {
        background-color: #cecece !important;
        border-radius: 5px;
        color: black !important;
      }
      &:hover {
        cursor: pointer;
        background-color: #e9e9e9;
        border-radius: 5px;
        color: #71181a;
      }
    }
    &__icon {
      margin-right: 10px;
    }
  }
}
.--show {
  display: flex;
}
.--unset {
  color: gray;
}
</style>