<template>
  <div id="app">
    <div class="content-wrapper">
      <router-view />
      <j-footer />
    </div>
    <j-scroll-top />
  </div>
</template>

<script>
import jScrollTop from './components/j-components/j-scroll-top.vue';
export default {
  components: { jScrollTop },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
