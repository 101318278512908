<template>
  <div>
    <h2>{{ $t("pages.help") }}</h2>
  </div>
</template>

<script>
export default {
  name: "Help",
};
</script>

<style></style>
