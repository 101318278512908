import Vue from "vue";

//Buttons
import JBtn from "@/components/j-components/j-button.vue";
Vue.component("j-btn", JBtn);

//Card
import JCard from "@/components/j-components/j-card.vue";
Vue.component("j-card", JCard);

//Inputs
import JCheckbox from "@/components/j-components/j-checkbox.vue";
Vue.component("j-check", JCheckbox);
import JInput from "@/components/j-components/j-input.vue";
Vue.component("j-input", JInput);
import JSafety from "@/components/j-components/j-safety-code-input.vue";
Vue.component("j-safety", JSafety);

//Modal
import JModal from "@/components/j-components/j-modal.vue";
Vue.component("j-modal", JModal);

//Tabs
import JTabs from "@/components/j-components/j-tabs.vue";
Vue.component("j-tabs", JTabs);

//Tooltip
import JTooltip from "@/components/j-components/j-tooltip.vue";
Vue.component("j-tooltip", JTooltip);

//Wizard
import JWizard from "@/components/j-components/j-wizard.vue";
Vue.component("j-wizard", JWizard);

//Footer
import JFooter from "@/components/j-components/j-footer.vue";
Vue.component("j-footer", JFooter);

//Scan Modal
import JScanModal from "@/components/j-components/j-scan-modal.vue";
Vue.component("j-scan-modal", JScanModal);

//Dropdown
import JDropDown from "@/components/j-components/j-dropdown.vue"
Vue.component('j-dropdown', JDropDown);