<template>
  <div class="locale-changer">
    <j-dropdown
      :items="langs"
      :placeHolder="$i18n.locale"
      style="width: 200px"
      @choosed="(item) => ($i18n.locale = item)"
    />
  </div>
</template>

<script>
import jDropdown from "./j-components/j-dropdown.vue";
export default {
  components: { jDropdown },
  name: "locale-changer",
  data() {
    return { langs: ["en-gb", "de-ch", "fr-ch", "it-ch"] };
  },
};
</script>