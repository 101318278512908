<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90.12 90.13"
    class="done-animation"
  >
    <title>done</title>
    <g id="ebene1" data-name="Ebene 1">
      <line class="plc-1" x1="20.23" y1="53.01" x2="27.1" y2="46.14" />
      <polyline
        class="plc-2"
        points="63.46 26.13 71.01 31.05 71.27 30.72 42.05 67.48 42.05 67.48 20.53 53.19 20.53 53.19 25.96 46.03 25.68 46.5 40.5 56.28 40.23 56.62 63.92 26.43"
      />
    </g>
    <g id="Ebene_2" data-name="Ebene 2">
      <circle class="plc-3" cx="45.06" cy="45.06" r="41.06" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "j-done-animation",
};
</script>

<style lang="scss">
.plc-3 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: stroke 4s ease-out forwards;
}
@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes check-animation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
#ebene1 {
  animation-name: check-animation;
  animation-duration: 1s;
  transform-origin: center;
}
.done-animation {
  height: 300px;
  margin-top: 130px;
}
.plc-1,
.plc-3 {
  fill: none;
}
.plc-2 {
  fill: #166f16;
}
.plc-2,
.plc-3 {
  stroke: #166f16;
  stroke-miterlimit: 10;
}
.plc-3 {
  stroke-width: 8px;
}
</style>