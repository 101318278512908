var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"j-button",style:(_vm.colors)},[_c('div',{staticClass:"button",class:{
      '--lightrounded': _vm.lightRounded,
      '--rounded': _vm.rounded,
      '--hoverDarker': _vm.hoverDarker,
      '--hoverLighter': _vm.hoverLighter,
      '--notFullFilled': _vm.notColorFilled,
      '--disabled': _vm.disabled,
      '--borderless': _vm.noBorder,
    },on:{"click":function($event){$event.stopPropagation();return _vm.$emit('onClick')}}},[(_vm.iconPosition == 'left')?_c('div',{staticClass:"material-icons icon --left",class:{ '--noMargin': !_vm.title }},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.iconPosition == 'right')?_c('div',{staticClass:"material-icons icon --right",class:{ '--noMargin': !_vm.title }},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }