<template>
  <j-card rounded style="padding: 0 !important" class="wiz__card">
    <div class="wiz">
     <div class="wiz__nav">
        <div class="wiz__nav__item-container">
          <div
            class="wiz__nav__item"
            v-for="(item, index) in stepTitles"
            :key="item + index"
          >
            <div class="wiz__nav__container">
              <div
                class="wiz__nav__count"
                :class="{ '--active': active >= index }"
              >
                {{ index + 1 }}
              </div>
            </div>
            <div class="wiz__nav__text">
              {{ item }}
            </div>
          </div>
        </div>
        <div class="wiz__nav__image-container">
          <div class="wiz__nav__image-container__step">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360.83 392.09">
              <title>sa-logo-test</title>

              <g id="Ebene_1" data-name="Ebene 1">
                <circle class="cls-1" cx="167.48" cy="249.81" r="26.82" />
              </g>

              <g id="Ebene_2" data-name="Ebene 2" v-if="active >= 1">
                <rect
                  id="ebene2-rect"
                  class="cls-1"
                  x="112.49"
                  y="344.99"
                  width="118.03"
                  height="13.3"
                  transform="translate(-226.73 71.14) rotate(-30)"
                />
                <circle
                  id="ebene2-circ"
                  class="cls-1"
                  cx="29.83"
                  cy="325.43"
                  r="29.83"
                />
              </g>

              <g id="Ebene_3" data-name="Ebene 3" v-if="active >= 2">
                <rect
                  class="cls-1"
                  x="160.21"
                  y="135.46"
                  width="23"
                  height="241.57"
                  transform="translate(69.51 -112.63) rotate(28.56)"
                />
                <circle class="cls-1" cx="176.19" cy="49.72" r="49.72" />
              </g>

              <g id="Ebene_4" data-name="Ebene 4" v-if="active >= 3">
                <rect
                  class="cls-1"
                  x="194.48"
                  y="240.58"
                  width="255.16"
                  height="37.23"
                  transform="translate(346.56 -204.06) rotate(64.94)"
                />
                <circle class="cls-1" cx="315.55" cy="346.81" r="45.28" />
              </g>
            </svg>
          </div>
          <!-- :style="{ backgroundImage: image[active] }"  -->
          <div class="wiz__nav__image">
            <img src="@/assets/images/svg/sa-logo-svg.svg" class="sa-logo" />
          </div>
        </div>
      </div> 
      <div class="wiz__content">
        <div
          class="wiz__content__item"
          v-for="(item, index) in stepTitles"
          :key="index"
          :style="{ display: active == index ? 'flex' : 'none' }"
        >
          <slot :name="'step' + index"></slot>
        </div>
        <div class="wiz__content__btns">
          <j-btn
            :title="$t('actions.back')"
            color="#b42629"
            hoverLighter
            rounded
            @onClick="active--"
            v-if="active > 0"
          />
          <div v-else></div>
          <!-- <j-btn
            :title="$t('actions.finish')"
            color="#b42629"
            hoverLighter
            rounded
            @onClick="finish"
            v-if="active == stepTitles.length - 1"
          /> -->
          <j-btn
            :title="$t('actions.continue')"
            color="#b42629"
            hoverLighter
            rounded
            @onClick="checkBefore"
            v-if="active < stepTitles.length - 1"
          />
        </div>
      </div>
    </div>
  </j-card>
</template>

<script>
export default {
  name: "j-wizard-2",
  props: {
    stepTitles: Array,
    // need to return Bool
    checkBeforeNextStep: {
      type: Function,
      default() {
        return this.noCheck();
      },
    },
  },
  data() {
    return {
      active: 0,
      image: {
        0: 'url("~@/assets/images/svg/sa-logo-step-1.svg")',
        1: 'url("~@/assets/images/svg/sa-logo-step-2.svg")',
        2: 'url("~@/assets/images/svg/sa-logo-step-3.svg")',
        3: 'url("~@/assets/images/svg/sa-logo-step-4.svg")',
      },
    };
  },
  methods: {
    finish: function () {
      this.$emit("finished");
    },
    checkBefore: function () {
      var test = this.checkBeforeNextStep(this.active);
      if (test == true) {
        this.active++;
      }
    },
    noCheck: function () {
      return true;
    },
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 1100px) {
  .wiz {
    flex-direction: column !important;
    &__nav {
      padding-top: 20px !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 15px;
      border-right: none !important;
      border-bottom: 1px solid #bdbdbd;
      box-shadow: none !important;
      &__image-container {
        height: 204px !important;
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 !important;
      }
      &__item-container {
        display: none !important;
      }
    }
    &__content {
      min-height: fit-content !important;
    }
  }
  .min-heigth-200 {
    min-height: 0 !important;
  }
  .sa-logo,
  .wiz__nav__image-container__step {
    width: 170px !important;
  }
  .done-animation {
    margin-top: 10px !important;
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 1385px) {
  .wizard__row {
    flex-direction: column !important;
  }
  .wizard__input:nth-child(even) {
    margin-left: 0 !important;
  }
  .wizard__single-input {
    width: 400px !important;
  }
}
.cls-1 {
  fill: #c1272d;
}
.sa-logo {
  width: 280px;
  margin-left: 15px;
}
.wiz {
  display: flex;
  flex-direction: row;
  padding: 50px;
  border: 1px solid rgb(212, 212, 212);
  border-radius: 10px;
  &__nav {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding-top: 50px;
    min-width: 310px;
    &__item {
      display: flex;
      flex-direction: row;
      margin: 0 30px;
    }
    &__text {
      margin-top: 10px;
      margin-bottom: 60px;
    }
    &__image-container {
      position: relative;
      height: 350px;
      margin-top: 70px;
      &__step {
        position: absolute;
        height: 305px;
        z-index: 1;
        opacity: 0.8;
        width: 280px;
        margin-left: 15px;
      }
    }
    &__image {
      position: absolute;
      z-index: 1;
      opacity: 0.1;
      height: 305px;
    }
    &__count {
      padding: 10px 17px;
      background-color: #efe0e1;
      color: white;
      border-radius: 50%;
      margin-right: 15px;
      &.--active {
        background-color: #ca4c51 !important;
      }
    }
  }
  &__content {
    padding: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 878px;
    &__item {
      height: 100%;
    }
    &__btns {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>