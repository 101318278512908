<template>
  <div class="code-input">
    <div v-for="index in codeLength" :key="index" v-on:keyup.delete="deleteBtn">
      <input
        type="text"
        class="code-input__item"
        v-model="code[index - 1]"
        maxlength="1"
        :ref="'item' + (index - 1)"
        :disabled="disabled"
        :style="{marginRight: index  == codeLength ? '0 !important': '20px' }"
      />
    </div>
  </div>
</template>

<script>
export default {
  //@code = value
  name: "j-safety-code-input",
  props: {
    //how much boxes
    codeLength: {
      type: Number,
      require: true,
    },
    val: String,
    disabled: Boolean,
  },
  data() {
    return {
      code: [],
    };
  },
  methods: {
    deleteBtn: function () {
      this.code.splice(this.code.length - 1, 1);
    },
  },
  watch: {
    code: function (v) {
      var l = v.length;
      if (l < this.codeLength) {
        this.$refs["item" + l][0].focus();
      }
      if (this.codeLength == l) {
        this.$emit("code", this.code.join(""));
      }
    },
    val: function (v) {
      if (v.length > 0) {
        this.code = [];
        var val = v.split("");
        for (var i = 0; i < val.length; i++) {
          this.code.push(val[i]);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.code-input {
  display: flex;
  flex-direction: row;
  &__item {
    border: 1px solid grey;
    padding: 10px;
    width: 50px;
    font-size: 25px;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 0 6px #cacaca;
  }
}
</style>